<div class="linkurl-drag">
    <div class="wrap-upload">
              <p-fileUpload accept="image/jpeg,image/png,image/jpg,image/gif,.mp4,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf,application/msword,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.wordprocessingml.document" *ngIf="!isUpload" [chooseLabel]="''" [chooseIcon]="''"  
              [multiple]="isUploadMultiple ? true : null" [showUploadButton]="false" [showCancelButton]="false" [customUpload]="true" name="demo[]" 
               (onSelect)="onChangeValue($event, element.field_name, element)" [maxFileSize]="10000000">
                  <ng-template pTemplate="toolbar">
                  </ng-template>
                  <ng-template pTemplate="content">
                    <div class="content-upload text-center">
                          <h3 style="color: #182850;">Tải tệp & Kéo tệp</h3>
                          <p>Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</p>
                    </div>
                  </ng-template>
              </p-fileUpload>
            </div>
            <div class="file-uploaded" *ngIf="element.columnValue && element.columnValue.length > 0">
              <h3 class="uploaded-title">Đã upload xong {{ element.columnValue.length }} file</h3>
              <!-- <ul *ngIf="uploadedFiles.length > 0">
                  <li class="d-flex middle bet" *ngFor="let file of uploadedFiles; let i=index">{{file}} 
                    <span (click)="removeImage(i)">
                        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.33366 5.33341V12.0001H2.66699V5.33341H9.33366ZM8.33366 0.666748H3.66699L3.00033 1.33341H0.666992V2.66675H11.3337V1.33341H9.00033L8.33366 0.666748ZM10.667 4.00008H1.33366V12.0001C1.33366 12.7334 1.93366 13.3334 2.66699 13.3334H9.33366C10.067 13.3334 10.667 12.7334 10.667 12.0001V4.00008Z" fill="#FF3B49"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.00033 10.3334V6.66675H5.33366V10.3334H4.00033Z" fill="#FF3B49"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66699 10.3334V6.66675H8.00033V10.3334H6.66699Z" fill="#FF3B49"/>
                        </svg>
                    </span>
                  </li>
              </ul> -->
            </div>
            <!-- <div class="file-uploaded" *ngIf="element.columnValue && (element.columnValue.length > 0) && (uploadedFiles.length === 0)">
            <ul>
                <li class="d-flex middle bet" *ngFor="let file of element.columnValue; let i=index">{{file}} 
                  <span (click)="removeImage1(i)">
                      <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.33366 5.33341V12.0001H2.66699V5.33341H9.33366ZM8.33366 0.666748H3.66699L3.00033 1.33341H0.666992V2.66675H11.3337V1.33341H9.00033L8.33366 0.666748ZM10.667 4.00008H1.33366V12.0001C1.33366 12.7334 1.93366 13.3334 2.66699 13.3334H9.33366C10.067 13.3334 10.667 12.7334 10.667 12.0001V4.00008Z" fill="#FF3B49"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.00033 10.3334V6.66675H5.33366V10.3334H4.00033Z" fill="#FF3B49"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66699 10.3334V6.66675H8.00033V10.3334H6.66699Z" fill="#FF3B49"/>
                      </svg>
                  </span>
                </li>
            </ul>
            </div> -->
        </div>