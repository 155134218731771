import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconControlSvgComponent } from './icon-control-svg.component';
@NgModule({
  declarations: [
    IconControlSvgComponent,
   ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    IconControlSvgComponent,
  ],
  entryComponents: [],
  providers: []
})
export class IconControlSvgModule { }
