import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {  FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextControlModule } from './text-control/text-control.module';
import { DropdownControlModule } from './dropdown-control/dropdown-control.module';
import { CurrencyControlModule } from './currency-control/currency-control.module';
import { NumberControlModule } from './number-control/number-control.module';
import { TextAreaControlModule } from './textarea-control/textarea-control.module';
import { MarkdownControlModule } from './markdown-control/markdown-control.module';
import { IconControlModule } from './icon-control/icon-control.module';
import { IconControlSvgModule } from './icon-control/icon-control-svg/icon-control-svg.module';
@NgModule({
  declarations: [
    
  ],
  imports: [
    CommonModule,
    FormsModule,
    TextControlModule,
    ReactiveFormsModule,
    DropdownControlModule,
    CurrencyControlModule,
    NumberControlModule,
    TextAreaControlModule,
    MarkdownControlModule,
    IconControlModule,
    IconControlSvgModule
  ],
  exports: [
  ]
})
export class UniControlModule { }
