import { Injectable } from '@angular/core';
import {  UserManagerSettings, User } from 'oidc-client';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { MessageService } from 'primeng/api';
import { AppConfigService } from 'src/config/app-config.service';
import { parseJwt } from '../utils/fn';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AuthService {
  constructor(private http: HttpClient,
    private _environment: AppConfigService,
    private readonly oauthService: OAuthService,
    private messageService: MessageService,
  ) {
  }


  getClaims(): any {
    // return this.user.profile;
  }

  getUser() {
    return this.user;
  }

  getAuthorizationHeaderValue(): string {
    return this.oauthService.getAccessToken() ? `Bearer ${this.oauthService.getAccessToken()}` : '';
  }

  getAccessTokenValue(): string {
    return this.oauthService.getAccessToken();
  }

  getUserName(): string {
    const tokenData = parseJwt(this.oauthService.getAccessToken());
    return tokenData.preferred_username;
  }

  getUserId(): string {
    const tokenData = parseJwt(this.oauthService.getAccessToken());
    return tokenData.sub;
  }

  isExpired(): boolean {
    return this.user.expired;
  }


  getUserImage(): string {
    return localStorage.getItem('avatarUrl') || '';
  }

  async getEmpDetail() {
    // if (localStorage.getItem("employeeId") === null) {


  }

  getWorkingProject() {
    return localStorage.getItem('projectCd');
  }

  getTokenChatApi(): Observable<any> {
    const url = `${this._environment.apSocketChatServer}/v1/login`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*'
      })
    };
    return this.http.post<any>(url, {
      serviceName: 'identityserver4',
      accessToken: this.user.access_token,
      expiresIn: this.user.expires_in
    }, options);
  }

  getCustomToken(token: string): Promise<any> {
    const url = `${this._environment.apSocketChatServer}/getCustomToken`;
    return this.http.post(url, {
      data: {
        access_token: token
      }
    }).pipe(
      map((response: any) => response.result)
    ).toPromise();
  }

  private user: any = null;

}


export function getClientSettings(): UserManagerSettings {
  return {
    authority: 'https://api.sunshinegroup.vn:5000',
    client_id: 'web_s_service_dev',
    redirect_uri: 'http://localhost:4200/auth-callback',
    post_logout_redirect_uri: 'http://localhost:4200',
    response_type: 'id_token token',
    scope: 'openid profile api_sre',
    filterProtocolClaims: true,
    loadUserInfo: true,
    automaticSilentRenew: true,
    silent_redirect_uri: 'http://localhost:4200/silent-refresh.html'
  };
}
