import { CommonModule } from '@angular/common';
import {  NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { DropdownControlComponent } from './dropdown-control.component';
import { AvatarModule } from 'primeng/avatar';
import { SafeHtmlPipe } from './safehtml.pipe';
import { NameAvatarPipe } from './fullname-avatar.pipe';

@NgModule({
  declarations: [
    DropdownControlComponent,
    SafeHtmlPipe,
    NameAvatarPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    DropdownModule,
    AvatarModule
  ],
  exports: [
    DropdownControlComponent
  ]
})
export class DropdownControlModule { }
