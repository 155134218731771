<div class="group-date" [ngClass]=" element.columnValue ? 'valid' : 'invalid' ">
    <label class="text-nowrap label-text" >{{element.columnLabel}} <span style="color:red" *ngIf="element.isRequire">*</span></label>

    <p-calendar panelStyleClass="datepicker-default" placeholder="DD/MM/YYYY" [appendTo]="'body'" [baseZIndex]="101" [disabled]="element.isDisable"
    [(ngModel)]="element.columnValue" [monthNavigator]="true" [yearNavigator]="true" (onBlur)="onChangeValue($event, element.field_name, element)" (onSelect)="onChangeValue($event, element.field_name, element)"
    yearRange="1900:2025" inputId="navigators" [required]="element.isRequire && element.isVisiable && !element.isEmpty"
    dateFormat="dd/mm/yy" name={{element.field_name}}></p-calendar>

    <div *ngIf="modelFields[element.field_name]?.isRequire && submit && modelFields[element.field_name]?.error"
          class="alert-validation alert-danger">
          <div [hidden]="!modelFields[element.field_name]?.error">
          {{modelFields[element.field_name]?.message}}
          </div>
   </div>
</div>