import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'lib-markdown-control',
  templateUrl: './markdown-control.component.html',
  styleUrls: ['./markdown-control.component.css']
})
export class MarkdownControlComponent implements OnInit {

  constructor() { }
  classInput = false;
  @Input() element;
  @Input() dataView;
  @Input() modelFields;
  @Input() detail;
  @Input() submit = false;
  modelMarkdow= null;
  @Output() callbackMarkdown = new EventEmitter<any>();
  ngOnInit(): void {
  }


  handleAttackFile() {
    
  }
  
  inputFocus(event) {
    if (!this.element.columnValue) {
      this.classInput = true;
    }
  }

  inputFocusOut(event) {
    if (this.element.columnValue) {
      this.classInput = true;
    } else {
      this.classInput = false;
    }
  }

  onChangeValue(event, field_name, element) {
     this.callbackMarkdown.emit({
      event: element,
      value: event.target.value,
      field: field_name
    })
  }


}
