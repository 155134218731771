import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconControlComponent } from './icon-control.component';
import { IconControlSvgComponent } from './icon-control-svg/icon-control-svg.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconControlSvgModule } from './icon-control-svg/icon-control-svg.module';
@NgModule({
  declarations: [
    IconControlComponent,
   ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconControlSvgModule
  ],
  exports: [
    IconControlComponent,
  ],
  entryComponents: [],
  providers: []
})
export class IconControlModule { }
