import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthConfig, NullValidationHandler, OAuthService} from 'angular-oauth2-oidc';
import {filter} from 'rxjs/operators';
import {AppConfigService} from './app-config.service';


@Injectable()
export class AuthConfigService {

  private _decodedAccessToken: any;
  private _decodedIDToken: any;

  get decodedAccessToken() {
    return this._decodedAccessToken;
  }

  get decodedIDToken() {
    return this._decodedIDToken;
  }

  constructor(
    private readonly oauthService: OAuthService,
    private readonly router: Router,
    private readonly authConfig: AuthConfig,
    private readonly appconfig: AppConfigService
  ) {
  }
  
  async initAuth(): Promise<any> {
    const redirectUri = `${window.location.origin}/auth-callback`;
    const logoutUrl = `${window.location.origin}/silent-refresh.html`;
    const s = await this.appconfig.loadConfig();
    return new Promise((resolveFn, rejectFn) => {
      // setup oauthService
      // delete s.apiServer;
      // delete s.apShomeServer;
      // delete s.apiCoreServer;
      // delete s.apiHrmConfig;
      // delete s.apiHrmServer;
      // delete s.socketServer;
      // delete s.apSocketChatServer;
      // delete s.cloudFunction;
      this.oauthService.configure({...s, redirectUri: redirectUri, logoutUrl: logoutUrl});
      this.oauthService.setStorage(localStorage);
      this.oauthService.tokenValidationHandler = new NullValidationHandler();

      // subscribe to token events
      this.oauthService.events
        .pipe(filter((e: any) => {
          return e.type === 'token_received';
        }))
        .subscribe(() => this.handleNewToken());
      // disabling keycloak for now
      // resolveFn();
      // continue initializing app or redirect to login-page

      this.oauthService.loadDiscoveryDocumentAndLogin().then(isLoggedIn => {
        if (isLoggedIn) {
          this.oauthService.setupAutomaticSilentRefresh();
          if (window.location.pathname === '/auth-callback' || window.location.pathname === '' || window.location.pathname === '/') {
            this.router.navigateByUrl('/home');
          }
          resolveFn(true);
        } else {
          this.oauthService.initImplicitFlow();
          rejectFn();
        }
      });

    });
  }

  private handleNewToken() {
    this._decodedAccessToken = this.oauthService.getAccessToken();
    this._decodedIDToken = this.oauthService.getIdToken();
  }

}
