<div class="group-dates" [ngClass]=" element.columnValue ? 'valid' : 'invalid' ">
    <label class="text-nowrap label-text">{{element.columnLabel}} <span style="color:red"
            *ngIf="element.isRequire">*</span></label>

    <p-calendar panelStyleClass="datepicker-default" placeholder="DD/MM/YYYY" [appendTo]="'body'" [baseZIndex]="101"
        [disabled]="element.isDisable" inputId="multiple" [(ngModel)]="element.columnValue" selectionMode="multiple"
        [readonlyInput]="true" [monthNavigator]="true" [yearNavigator]="true"
        (onBlur)="onChangeValue($event, element.field_name, element)"
        (onClose)="onChangeValue($event, element.field_name, element)"
        (onSelect)="onChangeValue($event, element.field_name, element)" yearRange="1900:2025"
        [required]="element.isRequire && element.isVisiable && !element.isEmpty" dateFormat="dd/mm/yy"
        name={{element.field_name}}></p-calendar>
    <div class="list-date">
        <div *ngFor="let item of element.columnValue">
            <span>{{item | date: 'dd/MM/yyyy'}}</span>
        </div>
    </div>

    <div *ngIf="modelFields[element.field_name]?.isRequire && submit && modelFields[element.field_name]?.error"
        class="alert-validation alert-danger">
        <div [hidden]="!modelFields[element.field_name]?.error">
            {{modelFields[element.field_name]?.message}}
        </div>
    </div>
</div>