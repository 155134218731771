<div class="checkbox-default">
    <label class="text-nowrap label-text" >{{element.columnLabel}} <span style="color:red" *ngIf="element.isRequire">*</span></label>
    <div class="checkbox-content">
      <p-checkbox name={{element.field_name}} [binary]="true" label="{{element.columnLabel}}"
      [required]="element.isRequire && element.isVisiable && !element.isEmpty" [disabled]="element.isDisable"
      [(ngModel)]="element.columnValue"></p-checkbox>

    <div *ngIf="element.isRequire && submit && !element.columnValue"
        class="alert-validation alert-danger">
        <div [hidden]="element.columnValue">
        Trường bắt buộc nhập!
        </div>
    </div>
</div>
</div>