<div class="section">
  <ag-grid-angular
    [style.height]="domLayout === '' ? height + 'px' : ''"
    [excelStyles]="excelStyles"
    [getContextMenuItems]="getContextMenuItems"
     #agGrid style="width: 100% "
     id="{{idGrid}}"
     class="ag-theme-balham {{listsData?.length > 0 ? '' : 'hidden_row'}}"
    [pinnedTopRowData]="pinnedTopRowData"
    [pinnedBottomRowData]="pinnedBottomData"
    [domLayout]="domLayout"
    [defaultExcelExportParams]="excelExportParams"
    [defaultCsvExportParams]="csvExportParams"
    [rowData]="listsData"
    [columnDefs]="columnDefs"
    (rowDataChanged)="autoSizeAll1()"
    [skipHeaderOnAutoSize]="false"
    [suppressPropertyNamesCheck]="true"
    [headerHeight]="headerHeight"
    [floatingFiltersHeight]="floatingFiltersHeight"
    [isRowMaster]="isRowMaster"
    [groupDefaultExpanded]="groupDefaultExpanded"
    [masterDetail]="true"
    [stopEditingWhenCellsLoseFocus]="true"    [modules]="modules"
    [rowClassRules]="rowClassRules"
    [detailCellRendererParams]="detailCellRendererParams"
  [detailCellRenderer]="detailCellRenderer"
    [tooltipShowDelay]="tooltipShowDelay"
    [rowSelection]="rowSelection"
    (gridReady)="onGridReady($event)"
    (rowDoubleClicked)="RowDoubleClicked($event)"
    (cellDoubleClicked)="CellDoubleClicked($event)"
    [defaultColDef]="defaultColDef"
    [enableCellTextSelection]="true"
    [suppressCopyRowsToClipboard]="true"
    [enableRangeSelection]="true"
    [groupDisplayType]="'groupRows'"
    [getRowHeight]="getRowHeight"
    (rowSelected)="onRowSelected($event)"
    (cellValueChanged)="onCellValueChanged($event)"
    [accentedSort]="true"
    (cellClicked)="CellClicked($event)"
    [isRowSelectable]="isRowSelectable"
    [getMainMenuItems]="getMainMenuItems"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    [overlayLoadingTemplate]="overlayLoadingTemplate"
    (firstDataRendered)="onFirstDataRendered($event)"
    (selectionChanged)="OnSelectionChanged($event)"
    [frameworkComponents]="frameworkComponents">
  </ag-grid-angular>
</div>
<p-button *ngIf="isShowButton" label="Add row" icon="pi pi-plus" styleClass="p-button-sm mt-1" (click)="addRow()"></p-button>
<!--[groupDisplayType]="'groupRows'"-->
<!--(bodyScroll)="handleScroll($event)"-->

<!-- [sideBar]="sideBar" -->

<!-- [suppressRowClickSelection]="true" -->

