import { Component } from '@angular/core';

@Component({
  selector: 'icon-control-svg',
  templateUrl: './icon-control-svg.component.html'
})
export class IconControlSvgComponent {
  constructor() {}

}
