<div class="group-dropdown" [ngClass]=" element.columnValue ? 'valid' : 'invalid' ">
    <label class="text-nowrap label-text">{{element.columnLabel}} <span style="color:red"
            *ngIf="element.isRequire">*</span></label>
    <div>
        <p-dropdown [appendTo]="'body'" [baseZIndex]="100" [autoDisplayFirst]="false" [disabled]="element.isDisable"
        [options]="element.options" (onChange)="onChangeValue($event.value, element.field_name, element)"
        [required]="element.isRequire && element.isVisiable && !element.isEmpty" [(ngModel)]="element.columnValue"
        name={{element.field_name}} [filter]="true">
      <ng-template let-item pTemplate="selectedItem">
        <div class="flex align-items-center gap-2" *ngIf="item?.isHtml">
          <div [innerHTML]="item?.name | safehtml: 'html'"></div>
        </div>
        <div class="flex align-items-center gap-2" *ngIf="!item?.isHtml">
          <ng-container *ngIf="item?.icon_is">
            <img *ngIf="item?.icon" style="width: 18px" src="{{item?.icon}}" alt="">
            <p-avatar *ngIf="!item?.icon" [label]="item?.name | nameavatar" styleClass="mr-2" size="large"
                      [style]="{ 'background-color': '#2196F3', color: '#ffffff' }"  [shape]="'circle'">
            </p-avatar>
          </ng-container>
          <div>{{ item?.name}}</div>
        </div>
      </ng-template>
      <ng-template let-country pTemplate="item">
        <div class="flex align-items-center gap-2" *ngIf="country?.isHtml">
          <div *ngIf="country.name" [innerHTML]="country.name | safehtml: 'html'"></div>
        </div>
        <div class="flex align-items-center gap-2" *ngIf="!country?.isHtml">
          <ng-container *ngIf="country?.icon_is">
            <img *ngIf="country?.icon" style="width: 18px" src="{{country?.icon}}" alt="">
            <p-avatar *ngIf="!country?.icon" [label]="country?.name | nameavatar" styleClass="mr-2" size="large"
                      [style]="{ 'background-color': '#2196F3', color: '#ffffff' }"  [shape]="'circle'">
            </p-avatar>
          </ng-container>
          <div>{{ country?.name}}</div>
        </div>
      </ng-template>

</p-dropdown>
        <div *ngIf="modelFields[element.field_name]?.isRequire && submit && modelFields[element.field_name]?.error"
            class="alert-validation alert-danger">
            <div [hidden]="!modelFields[element.field_name]?.error">
                {{modelFields[element.field_name]?.message}}
            </div>
        </div>
    </div>
</div>