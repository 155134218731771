import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lib-textarea-control',
  templateUrl: './textarea-control.component.html',
  styleUrls: ['./textarea-control.component.css']
})
export class TextareaControlComponent implements OnInit {

  constructor() { }
  classInput = false;
  @Input() element;
  @Input() dataView;
  @Input() modelFields;
  @Input() detail;
  @Input() submit = false;
  @Output() callbackInput = new EventEmitter<any>();
  ngOnInit(): void {
  }

  
  inputFocus(event) {
    if (!this.element.columnValue) {
      this.classInput = true;
    }
  }

  inputFocusOut(event) {
    if (this.element.columnValue) {
      this.classInput = true;
    } else {
      this.classInput = false;
    }
  }

  onChangeValue(event, field_name, element) {
     this.callbackInput.emit({
      event: element,
      value: event.target.value,
      field: field_name
    })
  }


}
