import { ChangeDetectorRef, Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ConfirmationService, MessageService } from 'primeng/api';

import queryString from 'query-string';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer-career',
  templateUrl: './footer-career.component.html',
  styleUrls: ['./footer-career.component.scss']
})
export class FooterCareerComponent implements OnInit {


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    public translate: TranslateService,
    private messageService: MessageService,
    private changeDetector: ChangeDetectorRef,
  ) {  }
  
  menuItems: any[] = [];

  ngOnInit() {
  }

}
