<div class="input-group">
    <label class="text-nowrap label-text">{{element.columnLabel}} <span style="color:red"
            *ngIf="element.isRequire">*</span></label>
    <div>
        <p-autoComplete [(ngModel)]="element.columnValue" [disabled]="element.isDisable" name={{element.field_name}} [baseZIndex]="100"
            [appendTo]="'body'" [style]="{width: '100%'}" [suggestions]="element.options"
            placeholder="Nhập Tìm kiếm theo tên" (onSelect)="onChangeValue($event.value, element.field_name, element)"
            (completeMethod)="search($event)" field="name"
            [required]="element.isRequire && element.isVisiable && !element.isEmpty"></p-autoComplete>
        <div *ngIf="modelFields[element.field_name]?.isRequire && submit && modelFields[element.field_name]?.error"
            class="alert-validation alert-danger">
            <div [hidden]="!modelFields[element.field_name]?.error">
                {{modelFields[element.field_name]?.message}}
            </div>
        </div>
    </div>
</div>