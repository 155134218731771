import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormControlDirective, FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DatetimesControlComponent } from './datetimes-control.component';
@NgModule({
  declarations: [
    DatetimesControlComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    CalendarModule
  ],
  exports: [
    DatetimesControlComponent
  ]
})
export class DatetimesControlModule { }
