import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import {
  IconControlModule,
  IconControlSvgModule
} from 'uni-control';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { ListGridAngularModule } from './common/list-grid-angular/list-grid-angular.module';
import { UniNotFoundComponent } from './components/uni-not-found/uni-not-found.component';
import { DefaultLayoutComponent } from './containers/default-layout';
import { LayoutQuanLyTuyenDungComponent } from './containers/layout-quan-ly-tuyen-dung/layout-quan-ly-tuyen-dung.component';
import { HomeComponent } from './pages/home/home.component';
import { ApiCoreService } from './services/api-core/apicore.service';
import { ApiHrmService } from './services/api-hrm/apihrm.service';
import { ApiHrmV2Service } from './services/api-hrm/apihrmv2.service';
import { ApiService } from './services/api.service';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthInterceptor } from './services/auth-interceptor';
import { AuthService } from './services/auth.service';
import { EmployeeSaveService } from './services/employee-save.service';
import { ErrorService } from './services/error.service';
import { ExportFileService } from './services/export-file.service';
import { FeedBaseService } from './services/firebase.service';
import { NavbarService } from './services/navbar.service';
import { NotificationService } from './services/notification.service';
import { OrganizeInfoService } from './services/organize-info.service';
import { ExcelComponent } from './shared/components/excel/excel.component';
import { ExcelModule } from './shared/components/excel/excel.module';
import { SharedModule } from './shared/shared.module';
import { SunshineComponent } from './sunshine/sunshine.component';
import { CheckboxEditorComponent } from './utils/common/checkbox-editor.component';
import { CurrencyDirectiveModule } from './utils/common/currency.module';
import { DropdownRendererComponent } from './utils/common/dropdown-renderer.component';
import { NumberCellRenderer } from './utils/common/number-renderer.component';
import { TextEditorComponent } from './utils/common/text-editor.component';
import { TooltipSuggestionComponent } from './utils/common/tooltip-suggestion.component';
import { AppConfigService, appConfigProvide } from 'src/config/app-config.service';
import { AuthConfigModule } from 'src/config/auth.config.module';
const APP_CONTAINERS = [DefaultLayoutComponent, LayoutQuanLyTuyenDungComponent];
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
    declarations: [
        AppComponent,
        ...APP_CONTAINERS,
        AuthCallbackComponent,
        SunshineComponent,
        HomeComponent,
        NumberCellRenderer,
        TextEditorComponent,
        CheckboxEditorComponent,
        TooltipSuggestionComponent,
        DropdownRendererComponent,
        UniNotFoundComponent
    ],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        ButtonModule,
        DialogModule,
        CurrencyDirectiveModule,
        ConfirmDialogModule,
        SplitButtonModule,
        ScrollPanelModule,
        NgxSpinnerModule,
        DataViewModule,
        ListGridAngularModule,
        BreadcrumbModule,
        OrganizationChartModule,
        MultiSelectModule,
        IconControlSvgModule,
        IconControlModule,
        SidebarModule,
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
          }
        }),
        // AgGridModule.withComponents([
        //     ButtonRendererComponent1,
        //     NumberCellRenderer,
        //     TextEditorComponent,
        //     CheckboxEditorComponent,
        //     CustomTooltipComponent,
        //     TooltipSuggestionComponent,
        //     DropdownRendererComponent,
        //   ]),
        ExcelModule,
        ToastModule,
        ChartModule,
        TabMenuModule,
        DropdownModule,
        CalendarModule,
        AutoCompleteModule,
        CardModule,
        TimelineModule,
        FullCalendarModule,
        TabViewModule,
        AvatarModule,
        AvatarGroupModule,
        AuthConfigModule
    ],
    entryComponents: [
        ExcelComponent
    ],
    providers: [
        {
          provide: APP_INITIALIZER,
          deps: [
            AppConfigService
          ],
          multi: true,
          useFactory: appConfigProvide
        },
        AppConfigService,
        AuthService,
        AuthGuardService,
        ExportFileService,
        ApiService,
        ApiHrmService,
        NavbarService,
        ApiCoreService,
        FeedBaseService,
        NotificationService,
        ConfirmationService,
        MessageService,
        OrganizeInfoService,
        EmployeeSaveService,
        ErrorService,
        DialogService,
        ApiHrmV2Service,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
