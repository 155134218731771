import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lib-number-control',
  templateUrl: './number-control.component.html',
  styleUrls: ['./number-control.component.css']
})
export class NumberControlComponent implements OnInit {

  constructor() { }
  @Input() element;
  @Input() modelFields;
  @Input() dataView;
  @Input() detail;
  @Input() submit = false;
  classInput = false
  @Output() callbackNumber = new EventEmitter<any>();
  ngOnInit(): void {
  }

  inputFocus(event) {
    if (!this.element.columnValue) {
      this.classInput = true;
    }
  }

  inputFocusOut(event) {
    if (this.element.columnValue) {
      this.classInput = true;
    } else {
      this.classInput = false;
    }
  }

  onChangeValue(event, field_name, element) {
    this.callbackNumber.emit({
      event: element,
      value: event.target.value,
      field: field_name
    })
  }

}
