import { ChangeDetectorRef, Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ConfirmationService, MessageService } from 'primeng/api';

import queryString from 'query-string';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header-career',
  templateUrl: './header-career.component.html',
  styleUrls: ['./header-career.component.scss']
})
export class HeaderCareerComponent implements OnInit {


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    public translate: TranslateService,
    private messageService: MessageService,
    private changeDetector: ChangeDetectorRef,
  ) {  }
  
  menuItems: any[] = [];

  ngOnInit() {
    this.menu();
  }

  menu() {
    this.menuItems = [
        {
          label: 'Trang chủ',
        },
        {
          label: 'Cơ hội nghề nghiệp',
        },
        {
          label: 'Về chúng tôi',
        },
        {
          label: 'Liên hệ',
        }
      ]
  }


  goToHome() {
    this.router.navigate(['/landing/viec-lam-tieu-bieu']);
  }

}
