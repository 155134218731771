<div class="group-field multi-select">
    <p-multiSelect [options]="element.options" [(ngModel)]="element.columnValue" [appendTo]="'body'"  (onChange)="onChangeValue($event, element.field_name, element)"
    name={{element.field_name}} defaultLabel="Select a option" optionLabel="name" display="chip">
  </p-multiSelect>
  <label class="text-nowrap label-text" >{{element.columnLabel}} <span style="color:red" *ngIf="element.isRequire">*</span></label>

  <div *ngIf="modelFields[element.field_name]?.isRequire && submit && modelFields[element.field_name]?.error"
          class="alert-validation alert-danger">
          <div [hidden]="!modelFields[element.field_name]?.error">
          {{modelFields[element.field_name]?.message}}
          </div>
   </div>
</div>