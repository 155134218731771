<div class="row grid">
      <div class="col-12">
        <div class="row form-group grid" >
          <div class="col-12 mt-2">
            <md-editor class="md-editor-binh-luan" id="content" name="content" [height]="'400px'" [(ngModel)]="element.columnValue"
               maxlength="2500"></md-editor>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="modelMarkdow.attack">
        <div class="form-group">
          <label for="title">Tệp đính kèm 
            <span class="ml-2 attack-file" (click)="handleAttackFile()">
              <i class="fa fa-paperclip" aria-hidden="true"></i>Chọn tệp đính kèm
            </span>
          </label>
          <!-- <app-attack-files [notify]="modelMarkdow"></app-attack-files> -->
        </div>
      </div>
     
</div>
  