import { Component, Input, } from '@angular/core';
@Component({
  selector: 'svg-icon',
  templateUrl: './icon-control.component.html',
  styleUrls: ['./icon-control.component.css'],
})
export class IconControlComponent {
  @Input() name: string;
  @Input() size = 16;
  @Input() fill = 'currentColor';
  @Input() nzTooltipMouseEnterDelay: string = '0.3';
  @Input() nzTooltipTitle = '';
  window: any = window;

  constructor() {}

  get iconUrl() {
    return `${this.window.location.href}#${this.name}`;
  }
}
