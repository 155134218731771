import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError, of, from} from 'rxjs';
import {catchError, retry, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {AuthService} from './auth.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService, private router: Router,
              private messageService: MessageService,
              private translate: TranslateService,
              private readonly oauthService: OAuthService,
              private spinner: NgxSpinnerService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('https://chat.sunshineapp.co')) {
      const tokenlogin = localStorage.hasOwnProperty('loginTokenChat')
      && localStorage.getItem('loginTokenChat')
        ? JSON.parse(localStorage.getItem('loginTokenChat'))
        : '';
      request = request.clone({
        setHeaders: {
          'X-Auth-Token': tokenlogin !== '' ? tokenlogin.authToken : '',
          'Accept-Language': this.translate.currentLang ? this.translate.currentLang : 'vi',
          'X-User-Id': tokenlogin !== '' ? tokenlogin.userId : '',
          // 'Content-Type': 'application/json',
        }
      });
    } else if (request.url.includes('realms/realm_uni_hrm') || request.url.includes('realms/realm_unihrm')) {
      request = request.clone({
        setHeaders: {
          // 'X-Role-Token': localStorage.hasOwnProperty('md5')
          // && localStorage.getItem('md5')
          //   ? localStorage.getItem('md5') : '',
          'Accept-Language': this.translate.currentLang ? this.translate.currentLang : 'vi',
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          'X-Role-Token': localStorage.hasOwnProperty('md5')
          && localStorage.getItem('md5')
            ? localStorage.getItem('md5') : '',
          'Accept-Language': this.translate.currentLang ? this.translate.currentLang : 'vi',
        }
      });

    }
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: any) => {
          if (error instanceof HttpErrorResponse) {
            return this.handleHttpError(error, request, next);
          } else {
            return throwError(error);
          }
        })
      );
  }

  private handleHttpError(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    switch (error.status) {
      case 401:
        return this.handle401Error(request, next);
      case 403:
        this.showMessage('error', 'Quyền truy cập bị từ chối. Vui lòng liên hệ đến Quản trị viên!');
        break;
      case 500:
        this.showMessage('error', 'Lỗi 500 !');
        break;
      case 0:
        this.showMessage('error', 'Lỗi 500 !');
        break;
    }
    return throwError(error);
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return from(this.oauthService.refreshToken()).pipe(
      switchMap(() => {
        // Sau khi refresh token thành công, thực hiện lại yêu cầu gốc với token mới
        const refreshedRequest = request.clone({
          setHeaders: {
            Authorization: `${this.auth.getAuthorizationHeaderValue()}`,
            'Accept-Language': this.translate.currentLang ? this.translate.currentLang : 'vi'
          }
        });
        return next.handle(refreshedRequest);
      }),
      catchError((refreshError: any) => {
        this.oauthService.logOut(); // Đăng xuất người dùng nếu refresh token thất bại
        this.spinner.hide();
        return throwError(refreshError);
      })
    );
  }

  private showMessage(severity: string, detail: string): void {
    this.messageService.add({severity: severity, summary: 'Thông báo', detail: detail});
    this.spinner.hide();
  }
}
