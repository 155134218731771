import {Component, HostListener, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ThemeService} from 'src/app/theme.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {
  public sidebarMinimized = false;
  isHorizontal: boolean = true;
  constructor(
    public router: Router, private themeService: ThemeService) {
    if (localStorage.hasOwnProperty('setting_menu') && localStorage.getItem('setting_menu') != null) {
      const setting_menu: string  = localStorage.getItem('setting_menu');
      this.isHorizontal = setting_menu === 'horizontal' ? true : false;
    } else {
      this.isHorizontal = true;
    }
  }

  ngOnInit() {

  }

  changeTheme(theme: string) {
    this.themeService.switchTheme(theme);
  }

  // @HostListener("window:scroll", []) onWindowScroll() {
  //   // do some stuff here when the window is scrolled
  //     const verticalOffset = window.pageYOffset
  //           || document.documentElement.scrollTop
  //           || document.body.scrollTop || 0;
  // }

}
