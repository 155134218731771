import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormControlDirective, FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { AutocompleteControlComponent } from './autocomplete-control.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
@NgModule({
  declarations: [
    AutocompleteControlComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    AutoCompleteModule
  ],
  exports: [
    AutocompleteControlComponent
  ]
})
export class AutocompleteControlModule { }
