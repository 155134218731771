import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import {FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { SelectTreeControlComponent } from './select-tree-control.component';
import { TreeSelectModule } from 'primeng/treeselect';
import { ChipModule } from 'primeng/chip';
@NgModule({
  declarations: [
    SelectTreeControlComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    TreeSelectModule,
    ChipModule
  ],
  exports: [
    SelectTreeControlComponent
  ]
})
export class SelectTreeControlModule { }
