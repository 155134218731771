<div class="news gap12">
    <h3>TIN MỚI</h3>
    <div class="slider d-flex">
        <div class="item">
            <h4>KienlongBank ưu đãi lãi vay chi từ 7,6%/năm danh cho khách hàng cá nhân</h4>
        </div>
        <div class="item">
            <h4>Hoàn phí rộn ràng, bảo vệ vững vàng cùng KienlongBank và Hanwha Life Việt Nam</h4>
        </div>
    </div>
<div>