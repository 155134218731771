import { AuthConfig } from 'angular-oauth2-oidc';
import data from '../assets/appsetings/appsetings.json';

export const authConfig: AuthConfig = {

    // Url of the Identity Provider
    issuer: data.issuer,

    // URL of the SPA to redirect the user to after login
    redirectUri: data.redirectUri,

    // The SPA is registerd with this id at the auth-serverß
    clientId: data.clientId,

    responseType: data.responseType,
    // set the scope for the permissions the client should request
    // The first three are defined by OIDC.
    scope: data.scope,
    // Remove the requirement of using Https to simplify the demo
    // THIS SHOULD NOT BE USED IN PRODUCTION
    // USE A CERTIFICATE FOR YOUR IDP
    // IN PRODUCTION
    requireHttps: data.requireHttps,
    logoutUrl: data.logoutUrl,
    // at_hash is not present in JWT token
    showDebugInformation: data.showDebugInformation,
    disableAtHashCheck: data.disableAtHashCheck
};


export class OAuthModuleConfig {
    resourceServer: OAuthResourceServerConfig = {sendAccessToken: false};
}

export class OAuthResourceServerConfig {
    /**
     * Urls for which calls should be intercepted.
     * If there is an ResourceServerErrorHandler registered, it is used for them.
     * If sendAccessToken is set to true, the access_token is send to them too.
     */
    allowedUrls?: Array<string>;
    sendAccessToken = true;
    customUrlValidation?: (url: string) => boolean;
}
