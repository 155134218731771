<div class="group-dropdown" [ngClass]=" element.columnValue ? 'valid' : 'invalid' ">
    <label class="text-nowrap label-tex" >{{element.columnLabel}}  <span *ngIf="element.columnValue">- [ {{element?.columnValue?.orgPath}} ]</span>  <span  style="color:red" *ngIf="element.isRequire">*</span></label>
    <div>
        <p-treeSelect [(ngModel)]="element.columnValue" [options]="element.options"
        filter="isFilter"
        [required]="element.isRequire 
        && element.isVisiable 
        && !element.isEmpty" (onNodeSelect)="onChangeValue($event, element.field_name, element)"
         [disabled]="element.isDisable" selectionMode="single"  placeholder="Select Item"></p-treeSelect>
          <div *ngIf="modelFields[element.field_name]?.isRequire && submit && modelFields[element.field_name]?.error"
              class="alert-validation alert-danger">
              <div [hidden]="!modelFields[element.field_name]?.error">
              {{modelFields[element.field_name]?.message}}
              </div>
          </div>
</div>
</div>