<div class="group-textarea">
    <label class="text-nowrap label-text">{{element.columnLabel}} <span style="color:red"
            *ngIf="element.isRequire">*</span></label>
    <div>
        <textarea type="text" placeholder="" class="form-control" (change)="onChangeValue($event, element.field_name, element)"
            [(ngModel)]="element.columnValue" name={{element.field_name}} [disabled]="element.isDisable"
            [required]="element.isRequire && element.isVisiable && !element.isEmpty"></textarea>

        <div *ngIf="modelFields[element.field_name]?.isRequire && submit && modelFields[element.field_name]?.error"
            class="alert-validation alert-danger">
            <div [hidden]="!modelFields[element.field_name]?.error">
                {{modelFields[element.field_name]?.message}}
            </div>
        </div>
    </div>
</div>