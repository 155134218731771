<div class="career" >
  <app-header-career></app-header-career>
  <div class="main">
    <router-outlet>
    </router-outlet>
  </div>
  <footer>
    <app-footer-career></app-footer-career>
  </footer>
</div>
