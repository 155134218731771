import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormControlDirective, FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { LinkurlControlComponent } from './linkurl-control.component';
@NgModule({
  declarations: [
    LinkurlControlComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    FileUploadModule
  ],
  exports: [
    LinkurlControlComponent
  ]
})
export class LinkurlControlModule { }
