<header>
  <div class="flex align-item-center">
    <div class="col-4">
      <a (click)="goToHome()" class="go-to-home">
        <img src="assets/images/KienlongBank.png">
      </a>
    </div>
    <div class="col-8">
      <p-menubar 
        [autoDisplay]="true"
        [model]="menuItems">
      </p-menubar>
    </div>
  </div>
</header>