import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormControlDirective, FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { MultiSelectControlComponent } from './multi-select-control.component';
import { MultiSelectModule } from 'primeng/multiselect';
@NgModule({
  declarations: [
    MultiSelectControlComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    MultiSelectModule
  ],
  exports: [
    MultiSelectControlComponent
  ]
})
export class MultiSelectControlModule { }
