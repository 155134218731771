import { Component } from '@angular/core';

@Component({
  selector: 'app-career-news',
  templateUrl: './career-news.component.html',
  styleUrls: ['./career-news.component.scss']
})
export class CareerNewsComponent {

}
