<div class="input-group">
    <label class="text-nowrap label-text" >{{element.columnLabel}} <span style="color:red" *ngIf="element.isRequire">*</span></label>
    <div>
      <input type="number" class="form-control" [(ngModel)]="element.columnValue"
      name={{element.field_name}} [disabled]="element.isDisable" (change)="onChangeValue($event, element.field_name, element)"
      [required]="element.isRequire && element.isVisiable && !element.isEmpty">

      <div *ngIf="modelFields[element.field_name]?.isRequire && submit && modelFields[element.field_name]?.error"
            class="alert-validation alert-danger">
            <div [hidden]="!modelFields[element.field_name]?.error">
            {{modelFields[element.field_name]?.message}}
            </div>
      </div>
  </div>
</div>