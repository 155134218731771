import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormControlDirective, FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxControlComponent } from './checkbox-control.component';
import { CheckboxModule } from 'primeng/checkbox';
@NgModule({
  declarations: [
    CheckboxControlComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    CheckboxModule
  ],
  exports: [
    CheckboxControlComponent
  ]
})
export class CheckboxControlModule { }
