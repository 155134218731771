<div class="group-field field-currency"
    [ngClass]="[element.columnValue || element.columnValue === 0 ? 'valid' : 'invalid']">
    <input maxLength=18 type="text" (change)="onChangeValue($event, element.field_name, element)" class="form-control"
        [(ngModel)]="element.columnValue" currency name={{element.field_name}} [disabled]="element.isDisable"
        [required]="element.isRequire && element.isVisiable && !element.isEmpty">
    <label class="text-nowrap label-text">{{element.columnLabel}} <span style="color:red"
            *ngIf="element.isRequire">*</span></label>
    <div *ngIf="modelFields[element.field_name]?.isRequire && submit && modelFields[element.field_name]?.error"
        class="alert-validation alert-danger">
        <div [hidden]="!modelFields[element.field_name]?.error">
            {{modelFields[element.field_name]?.message}}
        </div>
    </div>
</div>