<div class="group-field" [ngClass]="[element.columnValue ? 'valid' : 'invalid', classInput ? 'focused' : 'has-value', element.field_name === 'refer_saler_ref_cd' ? 'has-button': '' ]">
  <label class="text-nowrap label-text" >{{element.columnLabel}} <span style="color:red" *ngIf="element.isRequire">*</span></label>
  <input type="text" class="form-control" [(ngModel)]="element.columnValue" (change)="onChangeValue($event, element.field_name, element)"
    name={{element.field_name}} [disabled]="element.isDisable"
    (focus)="inputFocus($event)" 
    (focusout)="inputFocusOut($event)"
    [required]="element.isRequire && element.isVisiable && !element.isEmpty">
  <p-button label="Tìm" styleClass="p-button-sm" *ngIf="element.field_name === 'refer_saler_ref_cd' && detail?.book_st < 2" (click)="searchCustomer()"></p-button>
  <p-button label="Tìm" styleClass="p-button-sm h-100" [style]="{'height': '100%'}" *ngIf="element.field_name === 'rep_cif_no'" (click)="searchCustomer()"></p-button>
  <div *ngIf="submit && modelFields[element.field_name]?.error" class="alert-validation alert-danger"> 
    <div [hidden]="!modelFields[element.field_name]?.error">
      {{modelFields[element.field_name]?.message}}
    </div>
  </div>
</div> 