<div class="group-date" [ngClass]=" element.columnValue ? 'valid' : 'invalid' ">
    <label class="text-nowrap label-text">{{element.columnLabel}} <span style="color:red"
            *ngIf="element.isRequire">*</span></label>
    <div>
        <p-calendar placeholder="DD/MM/YYYY hh:mm" [appendTo]="'body'" [baseZIndex]="101" [disabled]="element.isDisable"
            (onSelect)="onChangeValue($event, element.field_name, element)" [(ngModel)]="element.columnValue"
            [monthNavigator]="true" [showTime]="true" hourFormat="24" [yearNavigator]="true" yearRange="2000:2030"
            inputId="navigators" [required]="element.isRequire && element.isVisiable && !element.isEmpty"
            dateFormat="dd/mm/yy" name={{element.field_name}}></p-calendar>

        <div *ngIf="modelFields[element.field_name]?.isRequire && submit && modelFields[element.field_name]?.error"
            class="alert-validation alert-danger">
            <div [hidden]="!modelFields[element.field_name]?.error">
                {{modelFields[element.field_name]?.message}}
            </div>
        </div>
    </div>
</div>