import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameavatar'
})
export class NameAvatarPipe implements PipeTransform {

  transform(value: string, digit: number = 2): string | null {
    if (value) {
      const newValues = value.split('-');
      return this.getStringName(newValues[1]);
    } else {
      return '';
    }
  }


  getStringName(name: string) {
    const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials: any = [...name.matchAll(rgx)] || [];

    initials = (
      (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();
    return initials;
  }

}
