import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormControlDirective, FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { TextareaControlComponent } from './textarea-control.component';

@NgModule({
  declarations: [
    TextareaControlComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule
  ],
  exports: [
    TextareaControlComponent
  ]
})
export class TextAreaControlModule { }
