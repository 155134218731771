import { Component } from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(  private primengConfig: PrimeNGConfig) {
    this.primengConfig.zIndex = {
      modal: 1100,    // dialog, sidebar
      overlay: 1000,  // dropdown, overlaypanel
      menu: 1000,
      tooltip: 1100,
      messages: 1200
    };
  }
}
