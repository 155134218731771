<div class="checkbox-default">
    <label class="text-nowrap label-text" >{{element.columnLabel}} <span style="color:red" *ngIf="element.isRequire">*</span></label>
    <div class="checkbox-content">
        <div *ngFor="let item of element.options">
            <div class="p-field-checkbox" style="display: flex;align-items: end;">
                <p-checkbox name="{{element.field_name}}" value="{{item.value}}" [(ngModel)]="element.columnValue" inputId="{{item.value}}"></p-checkbox>
                <label class="ml-1" for="{{item.value}}">{{item.label}}</label>
              </div>
          </div>

    <div *ngIf="element.isRequire && submit && !element.columnValue"
        class="alert-validation alert-danger">
        <div [hidden]="element.columnValue">
        Trường bắt buộc nhập!
        </div>
    </div>
</div>
</div>