  <app-career-news></app-career-news>
  <div class="footer">
    <div class="grid main">
      <div class="col-4 left">
        <img class="logo" src="assets/images/KienlongBank.png">
        <p>Tìm kiếm công việc trong mơ của bạn dễ dàng.</p>
        <ul class="social">
          <li><img src="assets/images/socials/1.svg"></li>
          <li><img src="assets/images/socials/2.svg"></li>
          <li><img src="assets/images/socials/3.svg"></li>
          <li><img src="assets/images/socials/4.svg"></li>
        </ul>
        <div class="app d-flex gap12">
          <img src="assets/images/ios.svg">
          <img src="assets/images/ch.svg">
        </div>
      </div>
      <div class="col-8 right">
        <h2>Ngân hàng TMCP Kiên Long (KienlongBank)</h2>
          <ul>
            <li>Hội sở: 40-42-44 Phạm Hồng Thái, P.Vĩnh Thanh Vân, Tp.Rạch Giá, T.Kiên Giang</li>
            <li>Điện thoại: (028) 3933 3393</li>
            <li>Hotline gọi trong lãnh thổ Việt Nam: 1900 6929</li>
            <li>Hotline gọi từ nước ngoài về Việt Nam: (+84) 287309 6929</li>
            <li>Mã SWIFT: KLBKVNVX</li>
            <li>Email: tuyendung@kienlongbank.com hoặc kienlong@kienlongbank.com</li>
          </ul>
      </div>
    </div>
    <div class="copyright">
      <div>Copyright © 2018 Kienlongbank. All right reserved.</div>
      <nav>
        <a href="#">Điều khoản</a>
        <svg width="1" height="16" viewBox="0 0 1 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="0.5" y1="2.18557e-08" x2="0.499999" y2="16" stroke="#E2E6F2"/>
        </svg>
        <a href="#">Bảo mật</a>
      </nav>
    </div>
  </div>