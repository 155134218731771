import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lib-timeonly-control',
  templateUrl: './timeonly-control.component.html',
  styleUrls: ['./timeonly-control.component.css']
})
export class TimeonlyControlComponent implements OnInit {
  @Input() element;
  @Input() modelFields;
  @Input() dataView;
  @Input() detail;
  @Input() submit = false;
  @Output() callbackTimeonly = new EventEmitter<any>();
  constructor() { }
  classInput = false;
  ngOnInit(): void {
  }

  onChangeValue(value, field_name, element) {
    this.modelFields[field_name].error = this.modelFields[field_name].isRequire && !this.element.columnValue ? true : false;
    this.modelFields[field_name].message = this.modelFields[field_name].error ? 'Trường bắt buộc nhập !' : ''
    this.callbackTimeonly.emit({
      event: element,
      value: value,
      field: field_name
    })
  }

  inputFocus(event) {
    if (!this.element.columnValue) {
      this.classInput = true;
    }
  }

  inputFocusOut(event) {
    if (this.element.columnValue) {
      this.classInput = true;
    } else {
      this.classInput = false;
    }
  }

}
