import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'lib-linkurl-control',
  templateUrl: './linkurl-control.component.html',
  styleUrls: ['./linkurl-control.component.css']
})
export class LinkurlControlComponent implements OnInit {
  @Input() element;
  @Input() isUploadMultiple = true;
  @Input() modelFields;
  @Input() dataView;
  @Input() detail;
  @Input() submit = false;
  isUpload = false;
  @Output() callbackDatetimes = new EventEmitter<any>();
  constructor() { }
  classInput = false;
  ngOnInit(): void {
  }

  onChangeValue(event, field_name, element) {
    this.isUpload = true;
    this.modelFields[field_name].error = this.modelFields[field_name].isRequire && !this.element.columnValue ? true : false;
    this.modelFields[field_name].message = this.modelFields[field_name].error ? 'Trường bắt buộc nhập !' : '';
    if(event.currentFiles.length > 0){
      this.callbackDatetimes.emit({
        event: element,
        value: event,
        field: field_name
      })

      setTimeout(() => {
        this.isUpload = false;
      }, 500);
    }
    
  }

  inputFocus(event) {
    if (!this.element.columnValue) {
      this.classInput = true;
    }
  }

  inputFocusOut(event) {
    if (this.element.columnValue) {
      this.classInput = true;
    } else {
      this.classInput = false;
    }
  }

}
