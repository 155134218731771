<div class="group-date" [ngClass]=" element.columnValue ? 'valid' : 'invalid' ">
    <label class="text-nowrap label-text">{{element.columnLabel}} <span style="color:red"
            *ngIf="element.isRequire">*</span></label>
    <div>
        <p-calendar placeholder="DD/MM/YYYY" [appendTo]="'body'" [baseZIndex]="101" [disabled]="element.isDisable"
                [(ngModel)]="element.columnValue" [timeOnly]="true" inputId="timeonly"
                [required]="element.isRequire && element.isVisiable && !element.isEmpty" placeholder="HH:mm" name={{element.field_name}}>
              </p-calendar>

        <div *ngIf="modelFields[element.field_name]?.isRequire && submit && modelFields[element.field_name]?.error"
            class="alert-validation alert-danger">
            <div [hidden]="!modelFields[element.field_name]?.error">
                {{modelFields[element.field_name]?.message}}
            </div>
        </div>
    </div>
</div>