import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AuthGuardService {

  constructor( private readonly oauthService: OAuthService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | Observable<boolean> | Promise<boolean>> {
    const isLoginSunshine = await  this.oauthService.getIdToken();
    if (!isLoginSunshine) {
      this.oauthService.initImplicitFlow();
    }
    return true;
  }

}
