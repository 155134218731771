import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'lib-checkbox-list-control',
  templateUrl: './checkbox-list-control.component.html',
  styleUrls: ['./checkbox-list-control.component.css']
})
export class CheckboxListControlComponent implements OnInit {

  constructor() { }
  classInput = false;
  @Input() element;
  @Input() dataView;
  @Input() modelFields;
  @Input() detail;
  @Input() submit = false;
  @Output() callbackCheckboxList = new EventEmitter<any>();
  ngOnInit(): void {
  }

  
  inputFocus(event) {
    if (!this.element.columnValue) {
      this.classInput = true;
    }
  }

  inputFocusOut(event) {
    if (this.element.columnValue) {
      this.classInput = true;
    } else {
      this.classInput = false;
    }
  }

  onChangeValue(event, field_name, element) {
     this.callbackCheckboxList.emit({
      event: element,
      value: event.target.value,
      field: field_name
    })
  }


}
