

<!-- <p-menu [model]="menuItems" [style]="{'width': '100%', 'height': '100%'}"></p-menu> -->
<ng-container *ngIf="isHorizontal; else vertical">
  <p-menubar [autoDisplay]="true" [hidden]="!isShowheader" [model]="menuItems" styleClass="sidebarBody" id="sidebarBody"></p-menubar>
</ng-container>
<ng-template #vertical>
  <p-menubar [autoDisplay]="true" [hidden]="!isShowheader" [style]="{'position': 'fixed', 'z-index': '-1', 'height': '15px', 'visibility': 'hidden' ,'width': '1px', 'overflow': 'hidden',}" [model]="menuItems" styleClass="sidebarBody" id="sidebarBody"></p-menubar>
  <p-panelMenu styleClass="horizontal" [model]="menuItems" [multiple]="true"></p-panelMenu>
</ng-template>

