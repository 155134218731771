import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LMarkdownEditorModule } from 'ngx-markdown-editor';
import { ButtonModule } from 'primeng/button';
import { TreeSelectModule } from 'primeng/treeselect';
import { MarkdownControlComponent } from './markdown-control.component';
@NgModule({
  declarations: [
    MarkdownControlComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    TreeSelectModule,
    LMarkdownEditorModule
  ],
  exports: [
    MarkdownControlComponent
  ]
})
export class MarkdownControlModule { }
